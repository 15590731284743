import { color, layout, position, space } from 'styled-system';
import styled from 'styled-components';

import { shouldForwardProp } from '../../utils/should-forward-prop';

const CarouselPepper = props => (
  <StyledSvg viewBox="0 0 544 958" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M540.895 303.718V303.112C540.895 303.112 540.895 302.766 540.895 302.593C537.242 270.071 526.885 238.652 510.476 210.306C490.618 181.088 464.42 156.696 433.82 138.936C426.476 133.436 420.001 128.239 413.918 123.043C402.185 112.865 396.535 102.255 397.274 90.5191V90.1727C397.393 87.5805 397.801 85.0095 398.491 82.5073C398.491 81.9444 398.795 81.468 398.925 80.9483L399.143 80.2121C402.445 66.3972 411.31 40.8462 433.907 24.9959L434.429 24.6495L434.82 24.1731C435.62 23.3395 436.204 22.3241 436.522 21.2149C436.84 20.1057 436.882 18.936 436.645 17.807C436.407 16.9973 435.991 16.2506 435.428 15.6213C434.864 14.992 434.166 14.4959 433.386 14.1692L407.139 0.830714C405.864 0.230351 404.462 -0.0520435 403.054 0.00788487H402.489C383.078 4.18641 365.989 15.5659 354.688 31.8384C343.431 47.8091 334.914 65.5316 329.483 84.2829V84.2829C325.138 93.8104 316.447 100.653 302.802 105.157C297.717 106.846 292.068 108.535 285.115 110.44H284.767C281.595 110.917 278.553 111.393 275.598 111.913C267.95 112.692 241.572 120.098 238.574 120.964L236.836 121.44V121.657L235.662 122.09C227.438 124.87 219.409 128.198 211.631 132.05C205.113 135.039 130.369 171.589 117.941 286.786C115.365 312.508 115.686 338.437 118.897 364.088C118.897 368.029 118.897 372.187 118.636 376.517V377.167V377.6C137.192 495.871 135.367 735.141 10.9966 882.904C10.7358 883.207 -14.2077 914.907 11.9091 944.399C19.1662 952.584 30.682 957.175 46.1522 957.998C68.649 958.109 90.9764 954.117 112.031 946.218V946.218C138.104 938.856 181.039 923.179 236.662 890.266C257.118 878.495 276.761 865.373 295.457 850.987L304.149 844.187C312.84 837.518 320.705 831.195 326.094 826.128C346.413 809.193 365.041 790.339 381.717 769.83L382.239 769.18C385.541 764.849 389.582 760.086 393.494 755.365C399.403 748.263 404.966 741.507 407.617 737.436C428.946 709.631 447.489 679.809 462.979 648.397L486.967 595.563C510.215 546.67 528.684 469.973 528.815 469.151C542.633 406.182 546.718 350.793 540.895 303.718Z" />
  </StyledSvg>
);

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp,
})`
  ${position}
  ${space}
  ${layout}
  ${color}
`;

export { CarouselPepper };
