import { color } from 'styled-system';
import styled from 'styled-components';

const DoubleX = props => (
  <StyledSvg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="90"
    viewBox="0 0 47 90"
    fill="currentColor"
    {...props}
  >
    <g clipPath="url(#a)">
      <path d="m19.31 88.445 3.516-1.729L27.665 72.8l12.762 5.26 3.507-1.752 3.516-1.73-1.589-3.832-1.568-3.843-12.792-5.272 4.84-13.916-1.61-3.823-1.588-3.833-3.517 1.73-3.525 1.707-4.83 13.938-12.762-5.26-.01-.021.01.022-3.516 1.73-3.507 1.75 1.588 3.834 1.56 3.82 12.791 5.272-4.818 13.906-.02.01.029.012 1.58 3.81 1.588 3.834 3.525-1.708ZM4.2 49.216l3.516-1.73 4.839-13.915 12.762 5.259 3.507-1.752 3.516-1.729-1.588-3.833-1.569-3.843-12.791-5.271L21.23 8.486l-1.608-3.823L18.032.83l-3.515 1.73-3.526 1.706-4.83 13.938L-6.6 12.945l-.009-.022.01.022-3.517 1.73-3.507 1.751 1.589 3.833 1.559 3.82 12.792 5.272-4.82 13.906-.02.01.03.012 1.58 3.811 1.588 3.833L4.2 49.216Z" />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 .798h47v89H0z" />
      </clipPath>
    </defs>
  </StyledSvg>
);

const StyledSvg = styled.svg`
  ${color}
`;

export { DoubleX };
