import { color, layout, position, space } from 'styled-system';
import styled from 'styled-components';

import { shouldForwardProp } from '../../utils/should-forward-prop';

const Pepper = props => (
  <StyledSvg
    width="42px"
    height="622px"
    viewBox="0 0 42 622"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="m278.636 153.2-.066-.385-.055-.33a152.577 152.577 0 0 0-29.212-55.315 151.795 151.795 0 0 0-56.322-37.077 253.308 253.308 0 0 1-14.34-7.953c-8.541-5.2-13.266-11.33-14.056-18.858l-.037-.22a22.497 22.497 0 0 1-.05-4.996c-.06-.357.082-.692.109-1.036l.059-.49c.615-9.124 3.502-26.294 16.148-38.78l.293-.275.198-.344a4.553 4.553 0 0 0 .475-4.237 3.66 3.66 0 0 0-2.459-1.96l-18.094-5.65a5.615 5.615 0 0 0-2.682-.084l-.358.06A50.37 50.37 0 0 0 131.254.605a113.31 113.31 0 0 0-10.375 35.993c-1.737 6.514-6.521 11.79-14.701 16.112-3.046 1.618-6.451 3.296-10.661 5.252l-.22.037a239.43 239.43 0 0 0-5.664 1.918c-4.772 1.316-20.724 8.846-22.534 9.718l-1.053.489.023.137-.698.4a125.229 125.229 0 0 0-14.188 8.902C47.365 82.158 3.834 113.378 8.301 187.83a222.223 222.223 0 0 0 8.9 48.965c.422 2.502.868 5.141 1.167 7.917l.07.413.047.275C42.952 318.482 67.46 470.553 4.353 577.688c-.133.221-12.568 23.019 7.177 38.937 5.485 4.416 13.288 6.095 23.198 4.957a119.099 119.099 0 0 0 40.56-14.545c15.764-7.471 41.339-22.028 73.122-48.888a340.904 340.904 0 0 0 33.113-31.24l4.788-5.249c4.803-5.165 9.118-10.023 11.995-13.817a263.933 263.933 0 0 0 29.274-41.703l.262-.468c1.632-3.104 3.686-6.561 5.663-9.977 2.99-5.142 5.797-10.027 7.043-12.896a344.41 344.41 0 0 0 25.596-62.457l9.561-36.109c9.515-33.53 13.013-84.194 13.007-84.73 2.019-41.451-1.33-77.048-10.076-106.303Z" />
  </StyledSvg>
);

const StyledSvg = styled.svg.withConfig({
  shouldForwardProp,
})`
  ${position}
  ${space}
  ${layout}
  ${color}
`;

export { Pepper };
