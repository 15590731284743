import { colors } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';

const PepperHeart = ({ fillPepper, fillHeart, fillOverlap, ...props }) => (
  <svg viewBox="0 0 173 300" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        d="M190.326 95.11v-.352a74.216 74.216 0 0 0-9.527-28.9 73.845 73.845 0 0 0-24.01-22.35c-2.3-1.722-4.328-3.35-6.233-4.977-3.675-3.187-5.445-6.51-5.213-10.185v-.108a10.9 10.9 0 0 1 .381-2.4c0-.177.095-.326.136-.489l.068-.23c1.034-4.327 3.811-12.328 10.888-17.291l.164-.109.122-.15a2.224 2.224 0 0 0 .572-1.993 1.776 1.776 0 0 0-1.021-1.139L148.432.26a2.729 2.729 0 0 0-1.279-.258h-.177a24.503 24.503 0 0 0-14.972 9.968 55.114 55.114 0 0 0-7.894 16.423c-1.361 2.984-4.083 5.127-8.357 6.537a108.182 108.182 0 0 1-5.54 1.655h-.109c-.993.149-1.946.298-2.872.46-2.395.245-10.657 2.564-11.596 2.835l-.544.15v.067l-.368.136a60.92 60.92 0 0 0-7.527 3.119c-2.041.936-25.452 12.382-29.345 48.456a108.1 108.1 0 0 0 .3 24.207c0 1.234 0 2.536-.082 3.892V118.246c5.812 37.037 5.24 111.965-33.714 158.237-.081.095-7.894 10.022.286 19.258 2.273 2.563 5.88 4.001 10.726 4.258A57.948 57.948 0 0 0 56 296.311c8.167-2.306 21.614-7.215 39.036-17.522a165.773 165.773 0 0 0 18.416-12.301l2.722-2.129c2.722-2.088 5.185-4.068 6.873-5.655a128.382 128.382 0 0 0 17.422-17.63l.163-.203c1.035-1.357 2.3-2.848 3.525-4.327 1.852-2.224 3.594-4.339 4.424-5.614a167.498 167.498 0 0 0 17.34-27.883l7.513-16.545c7.282-15.311 13.067-39.329 13.107-39.586 4.329-19.719 5.608-37.064 3.784-51.806Z"
        fill={fillPepper}
      />
      <path
        d="M77.413 175.763c0-.092-.122-.215-.182-.322l-.577-.982a84.419 84.419 0 0 1-10.383-34.313c-1.23-11.305-1.867-23.976-1.867-23.976-1.826-17.481-9.085-33.93-20.736-46.984-12.144 14.772-6.831 43.226-6.831 43.226-8.774 0-22.087-16.873-22.087-16.873a51.237 51.237 0 0 0 0 13.115c1.609 20.248 11.886 57.691 63.999 69.241 0 0-.304-.444-.805-1.257-.182-.277-.349-.568-.531-.875Z"
        fill={fillHeart}
      />
      <path
        d="M59.21 171.542c6.79 3.77 14.937 6.891 24.711 9.022 0 0-.275-.396-.728-1.119a14.527 14.527 0 0 1-.382-.615l-.099-.164c0-.055-.049-.121-.097-.188a1.062 1.062 0 0 1-.068-.099l-.522-.874a74.271 74.271 0 0 1-9.396-30.549c-1.113-10.065-1.69-21.345-1.69-21.345A73.79 73.79 0 0 0 58.1 91.223a74.74 74.74 0 0 0-.972 6.415 95.735 95.735 0 0 0 .267 21.504c0 1.096 0 2.253-.073 3.458v.301c1.961 12.457 3.107 29.741 1.889 48.641Z"
        fill={fillOverlap}
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.131)" d="M0 0h172v300H0z" />
      </clipPath>
    </defs>
  </svg>
);

PepperHeart.propTypes = {
  fillPepper: PropTypes.string,
  fillHeart: PropTypes.string,
  fillOverlap: PropTypes.string,
};

PepperHeart.defaultProps = {
  fillPepper: colors.green500,
  fillHeart: colors.periRed,
  fillOverlap: colors.yellow100,
};

export { PepperHeart };
