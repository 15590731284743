import { color } from 'styled-system';
import styled from 'styled-components';

const Arrow = props => (
  <StyledSvg viewBox="0 0 172 300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M172.131 125.091 48.832.5l-.576 123.669-86.318-87.2-.807 175.205 87.096-86.363-.576 122.689 124.48-123.409Z"
    />
  </StyledSvg>
);

const StyledSvg = styled.svg`
  ${color}
`;

export { Arrow };
