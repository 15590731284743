import { colors } from '@nandosaus/constants';
import { PropTypes } from 'prop-types';

const Flame = ({ fillMainFlame, fillSideFlame, ...props }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="58" height="102" viewBox="0 0 58 102" fill="none" {...props}>
      <mask id="a" width="58" height="102" x="0" y="3" maskUnits="userSpaceOnUse" style={{ maskType: 'alpha' }}>
        <path fill="#D9D9D9" d="M.093 3.828H58v101H.093z" />
      </mask>
      <g mask="url(#mask0_5517_1679)">
        <path
          fill={fillMainFlame}
          d="M20.655 16.448c-5.43-4.24-5.789-10.805-5.926-13.475a.146.146 0 0 1 .266-.093 15.392 15.392 0 0 0 4.775 4.457c8.858 5.013 6.555 14.717 5.709 17.414-.115.371-.248.354-.284-.035-.34-3.63-2.657-6.464-4.54-8.268Zm20.64 84.269a.413.413 0 0 1 .145.475.41.41 0 0 1-.42.268C10.748 99.52-3.642 73.369 14.455 47.223c3.49-5.058 7.907-9.558 12.912-15.73 2.865-3.537 7.79-14.59 1.665-21.597a.194.194 0 0 1 .204-.309c19.045 5.54 24.91 21.384 23.67 34.143-.674 6.95-2.538 12.556-4.563 15.677a.155.155 0 0 0 .173.235c5.2-1.455 8.779-6.561 9.722-8.042a.239.239 0 0 1 .443.044c.567 1.477 2.157 6.552.815 14.258-2.423 13.926-9.793 16.8-17.863 22.9-7.184 5.43-2.976 9.952-.337 11.915Z"
        />
        <path
          fill={fillSideFlame}
          d="M8.177 43.289c-.396.485-.57.353-.387-.218 1.678-5.413.052-10.859-1.546-14.584-5.27-9.319-1.863-18.954-.462-22.859a.227.227 0 0 1 .433.024 23.821 23.821 0 0 0 4.19 9.235c9.655 12.476.594 25.035-2.228 28.402Z"
        />
      </g>
    </svg>
  );
};

Flame.propTypes = {
  fillMainFlame: PropTypes.string,
  fillSideFlame: PropTypes.string,
};

Flame.defaultProps = {
  fillMainFlame: colors.orange500,
  fillSideFlame: colors.yellow400,
};

export { Flame };
