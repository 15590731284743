import PropTypes from 'prop-types';

import { Arrow } from './arrow';
import { CarouselFlame } from './carousel-flame';
import { CarouselPepper } from './carousel-pepper';
import { Cross } from './cross';
import { DoubleX } from './double-x';
import { Flame } from './flame';
import { logger } from '../../utils/logger';
import { Pepper } from './pepper';
import { PepperHeart } from './pepper-heart';
import { PepperLowerHalf } from './pepper-lower-half';
import { PepperUpperHalf } from './pepper-upper-half';

const decals = {
  arrow: Arrow,
  doubleX: DoubleX,
  carouselPepper: CarouselPepper,
  carouselFlame: CarouselFlame,
  cross: Cross,
  flame: Flame,
  pepper: Pepper,
  pepperHeart: PepperHeart,
  pepperLowerHalf: PepperLowerHalf,
  pepperUpperHalf: PepperUpperHalf,
};
const decalNames = Object.keys(decals);

const Decal = ({ name, height, width, color, ...rest }) => {
  const Component = decals[name];

  if (!Component) {
    logger.info('Missing decal', { name });
    return null;
  }

  return (
    <Component
      name={name}
      position="absolute"
      height={height}
      width={width}
      fill="currentColor"
      color={color}
      {...rest}
    />
  );
};

Decal.propTypes = {
  name: PropTypes.string.isRequired,
  height: PropTypes.string,
  width: PropTypes.string,
  color: PropTypes.string,
};

Decal.defaultProps = {
  height: '100%',
  width: '100%',
  color: null,
};

export { Decal, decalNames };
