import { color } from 'styled-system';
import styled from 'styled-components';

const PepperUpperHalf = props => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 45 115" {...props}>
    <path
      fillRule="evenodd"
      d="M40.925.98a1.31 1.31 0 0 1 .618.039l4.135 1.435c.246.065.45.238.554.47.117.328.065.693-.14.975l-.048.079-.07.061c-3.006 2.794-3.793 6.74-4 8.843l-.017.113c-.009.079-.044.155-.032.238-.051.383-.06.77-.024 1.155.136 1.796 1.183 3.245 3.116 4.508a58.464 58.464 0 0 0 3.253 1.94 35.058 35.058 0 0 1 12.733 8.968 35.29 35.29 0 0 1 6.35 12.987l.022.166c1.811 6.821 2.332 15.068 1.573 24.63-.003.123-1.168 11.802-3.6 19.48l-2.462 8.273a79.654 79.654 0 0 1-6.349 14.245c-.307.653-.99 1.762-1.716 2.928-.48.775-.979 1.559-1.377 2.264l-.064.107a61.034 61.034 0 0 1-7.05 9.424c-.69.856-1.72 1.947-2.865 3.106l-1.142 1.178a78.675 78.675 0 0 1-7.862 6.98c-7.523 5.977-13.528 9.157-17.218 10.77l-1.795.887a27.488 27.488 0 0 1-7.666 2.183c-2.295.191-4.084-.253-5.318-1.312-4.443-3.819-1.413-8.996-1.38-9.046 15.319-24.297 10.74-59.603 5.612-76.66l-.022-.16a30.73 30.73 0 0 0-.214-1.837 51.425 51.425 0 0 1-1.707-11.375C4.25 31.79 14.515 24.89 15.413 24.318a28.909 28.909 0 0 1 3.338-1.955l.164-.088-.005-.031.246-.106c.424-.188 4.158-1.814 5.269-2.083.424-.137.863-.268 1.32-.403a41.792 41.792 0 0 0 2.549-1.144c1.918-.94 3.059-2.124 3.505-3.617l.31-1.712a26.212 26.212 0 0 1 2.34-6.528 11.634 11.634 0 0 1 6.393-5.66z"
      clipRule="evenodd"
    />
  </StyledSvg>
);

const StyledSvg = styled.svg`
  ${color}
`;
export { PepperUpperHalf };
