import { layout, position, space, system } from 'styled-system';
import Imgix from 'react-imgix';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import StyledSystemPropTypes from '@styled-system/prop-types';

const imgixConfig = process.env.imgix;

const baseStyles = css`
  max-width: 100%;
  vertical-align: top;

  ${({ isBackground, objectPosition }) =>
    isBackground &&
    css`
      height: 100% !important;
      object-fit: cover;
      object-position: ${objectPosition};
      position: absolute;
      left: 0;
      top: 0;
      width: 100% !important;
    `}

  ${system({
    objectFit: true,
  })}

  ${layout}
  ${position}
  ${space}
`;

const StyledImage = styled.img`
  ${baseStyles}
`;

const StyledImgix = styled(Imgix)`
  ${baseStyles}
`;

// graphql focal point comes in like [0.5, 0.5] but we need "50% 50%"
const objectPosition = focalPoint => `${focalPoint[0] * 100}% ${focalPoint[1] * 100}%`;

const srcToImgixSrc = src => {
  if (!src) {
    return null;
  }
  const domainMapping = imgixConfig.domainMapping || [];
  const foundItem = domainMapping.find(item => src.startsWith(item.domain));

  if (foundItem) {
    return src.replace(foundItem.domain, foundItem.imgixDomain);
  }
  return src;
};

const canUseImgix = src => {
  const srcToLower = String(src).toLowerCase();
  const startsWithHttp = srcToLower.startsWith('http');
  const endsWithSvg = srcToLower.endsWith('svg');
  return startsWithHttp && !endsWithSvg;
};

const Image = ({
  aspectRatio,
  className,
  src,
  srcSet,
  loading,
  sizes,
  alt,
  focalPoint,
  isBackground,
  htmlAttributes,
  height, // passed-in height of the image rendered by parent component
  width, // passed-in width of the image rendered by parent component
  ...rest
}) => {
  const useImgix = useMemo(() => canUseImgix(src), [src]);
  const imageSource = useMemo(() => (useImgix ? srcToImgixSrc(src) : src), [useImgix, src]);
  const [focalPointX, focalPointY] = focalPoint;

  return !useImgix ? (
    <picture>
      <source srcSet={`${src}?webp`} type="image/webp" />
      <source srcSet={srcSet} type={`image/${src.split('.').pop()}`} />
      <StyledImage
        alt={alt}
        className={className}
        height={!sizes && height}
        width={!sizes && width}
        isBackground={isBackground}
        objectPosition={objectPosition(focalPoint)}
        position={position}
        sizes={sizes}
        src={imageSource}
        {...rest}
      />
    </picture>
  ) : (
    <StyledImgix
      htmlAttributes={{
        ...htmlAttributes,
        alt,
        loading,
      }}
      className={className}
      isBackground={isBackground}
      objectPosition={objectPosition(focalPoint)}
      position={position}
      sizes={sizes}
      height={!sizes && height} // height of the src image to pull from CDN if sizes prop not set
      width={!sizes && width} // width of the src image to pull from CDN if sizes prop not set
      src={imageSource}
      imgixParams={{ ar: aspectRatio, fit: 'crop', 'fp-x': focalPointX, 'fp-y': focalPointY, auto: 'format,compress' }}
      {...rest}
    />
  );
};

Image.propTypes = {
  ...StyledSystemPropTypes.layout,
  ...StyledSystemPropTypes.position,
  ...StyledSystemPropTypes.space,
  alt: PropTypes.string,
  className: PropTypes.string,
  focalPoint: PropTypes.arrayOf(PropTypes.number),
  isBackground: PropTypes.bool,
  position: PropTypes.string,
  sizes: PropTypes.string,
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  htmlAttributes: PropTypes.shape({}),
  loading: PropTypes.string,
};

Image.defaultProps = {
  alt: null,
  className: '',
  focalPoint: [0.5, 0.5],
  isBackground: false,
  position: null,
  sizes: null,
  srcSet: null,
  htmlAttributes: undefined,
  loading: undefined,
};

export { Image };
