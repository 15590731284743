import { color } from 'styled-system';
import styled from 'styled-components';

const PepperLowerHalf = props => (
  <StyledSvg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 41 100" {...props}>
    <path
      fillRule="evenodd"
      d="M-.648-50.162a1.32 1.32 0 0 0-.623.04l-4.162 1.445a.85.85 0 0 0-.558.473c-.118.33-.065.698.141.982l.048.079.07.062c3.027 2.812 3.82 6.784 4.027 8.901l.018.114c.008.08.044.156.032.24.052.385.06.775.024 1.162-.137 1.808-1.19 3.267-3.137 4.538a58.894 58.894 0 0 1-3.274 1.953 35.29 35.29 0 0 0-12.818 9.028A35.526 35.526 0 0 0-27.252-8.07l-.023.167c-1.823 6.866-2.347 15.168-1.583 24.793.003.125 1.176 11.881 3.625 19.61l2.478 8.328a80.165 80.165 0 0 0 6.39 14.34c.31.658.997 1.774 1.728 2.948.483.78.985 1.57 1.387 2.28l.064.106a61.384 61.384 0 0 0 7.096 9.487c.696.862 1.733 1.96 2.885 3.127l1.15 1.186a79.21 79.21 0 0 0 7.913 7.026c7.574 6.017 13.618 9.218 17.333 10.841l1.807.894a27.658 27.658 0 0 0 7.718 2.197c2.31.193 4.11-.254 5.353-1.32 4.473-3.844 1.422-9.056 1.39-9.107-15.422-24.459-10.813-60-5.65-77.17l.022-.16c.05-.649.135-1.266.215-1.85A51.77 51.77 0 0 0 35.764-1.8c.507-17.346-9.826-24.293-10.731-24.869a29.075 29.075 0 0 0-3.36-1.967l-.164-.089.004-.032-.248-.106c-.427-.19-4.186-1.826-5.303-2.097-.428-.137-.869-.27-1.33-.405a42.072 42.072 0 0 1-2.565-1.152c-1.93-.946-3.08-2.139-3.53-3.64l-.31-1.725a26.384 26.384 0 0 0-2.355-6.571A11.711 11.711 0 0 0-.565-50.15z"
      clipRule="evenodd"
    />
  </StyledSvg>
);

const StyledSvg = styled.svg`
  ${color}
`;
export { PepperLowerHalf };
