import { color } from 'styled-system';
import styled from 'styled-components';

const Cross = props => (
  <StyledSvg
    width="98px"
    height="281px"
    viewBox="0 0 98 281"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M-7.433.425-19.1 5.603l-11.57 5.214-.066.03-15.947 42.17-42.235-15.95-.067.031-11.539 5.28-11.637 5.245 5.191 11.654 5.227 11.556.031.067 42.234 15.948-15.916 42.237 5.324 11.593 5.258 11.623 11.636-5.244 11.667-5.177 15.983-42.266 42.235 15.948 11.636-5.244 11.607-5.311-5.258-11.624-5.227-11.557.067-.03-.098-.036-42.234-15.949L3.149 23.642l-.03-.067L-2.11 12.02-7.433.425ZM4.25 274.897l11.637-5.245 16.014-42.199 42.235 15.948 11.606-5.311 11.636-5.244-5.257-11.623-5.191-11.654-42.332-15.985 16.014-42.199-5.325-11.594-5.257-11.623-11.636 5.244-11.667 5.177-15.984 42.267-42.234-15.949-.03-.066.03.066-11.637 5.245-11.606 5.311 5.258 11.623 5.16 11.587 42.332 15.985-15.947 42.169-.067.03.097.037 5.227 11.557 5.258 11.623 11.667-5.177Z" />
  </StyledSvg>
);

const StyledSvg = styled.svg`
  ${color}
`;

export { Cross };
