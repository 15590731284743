import { includes, isString, map, size, split } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import StyledSystemPropTypes, { propType as StyledSystemPropType } from '@styled-system/prop-types';

import { Box, Flex } from '../grid';
import { H1, H2, H3 } from '../typography';

const HeadingWithLineHighlights = ({
  heading,
  color,
  highlightColor,
  highlightBackgroundColor,
  highlightPadding,
  borderRadius,
  overrideHeadingHighlighting,
  pb,
  mt,
  mb,
  headingLevel,
  shouldWrapLongLines,
  lineHeight,
  textAlign,
  justifyContent,
  ...rest
}) => {
  if (!heading) {
    return null;
  }

  if (!isString(heading)) {
    return (
      <H2 mt={mt} mb={mb} pb={pb ?? 1} {...rest}>
        {heading}
      </H2>
    );
  }

  const Comp = (() => {
    switch (headingLevel) {
      case 'h1':
        return H1;
      case 'h2':
        return H2;
      case 'h3':
        return H3;
      default:
        return H2;
    }
  })();

  const headingAsLines = split(heading, '\n');

  // If more than 1 line, don't wrap lines, so the CMS user can control where line breaks are placed and so, which lines are highlighted.
  // If only 1 line we need to support auto line wrapping, for compatibility with existing CMS entries.
  const autoWrapLongLines = size(headingAsLines) === 1 || shouldWrapLongLines;

  const alwaysHighlightFirstLine = includes(overrideHeadingHighlighting, 'alwaysHighlightFirstLine');
  const alwaysHighlightLastLine = includes(overrideHeadingHighlighting, 'alwaysHighlightLastLine');
  const highlightAllLines = includes(overrideHeadingHighlighting, 'highlightAllLines');
  return (
    <Flex justifyContent={justifyContent} width="100%">
      <Comp
        whiteSpace={autoWrapLongLines ? undefined : 'nowrap'}
        mt={mt}
        mb={mb}
        pb={pb ?? 1}
        textAlign={textAlign}
        color={color}
        width="100%"
        shouldWrapLongLines={shouldWrapLongLines}
        position="relative"
        {...rest}
      >
        {map(headingAsLines, (line, index) => {
          const isFirstLine = index === 0;
          const isLastLine = index === size(headingAsLines) - 1;
          const isFirstOrLastLine = index === 0 || isLastLine;
          let shouldHighlight = !isFirstOrLastLine;
          if (isFirstLine && alwaysHighlightFirstLine) {
            shouldHighlight = true;
          }
          if (highlightAllLines || (isLastLine && alwaysHighlightLastLine)) {
            shouldHighlight = true;
          }

          const backgroundColor = shouldHighlight ? highlightBackgroundColor : undefined;

          return (
            <Box
              key={index}
              as="span"
              backgroundColor={backgroundColor}
              mx="auto"
              width="fit-content"
              padding={highlightPadding || '0 0.6rem'}
              lineHeight={lineHeight}
              display={!autoWrapLongLines && 'block'}
              boxDecorationBreak={autoWrapLongLines && 'clone'}
              color={shouldHighlight ? highlightColor : undefined}
              borderRadius={borderRadius || '4px'}
              maxWidth="100%"
            >
              {line}
              {shouldWrapLongLines && <br />}
            </Box>
          );
        })}
      </Comp>
    </Flex>
  );
};

HeadingWithLineHighlights.propTypes = {
  heading: PropTypes.string.isRequired,
  highlightBackgroundColor: StyledSystemPropTypes.color.backgroundColor,
  highlightPadding: StyledSystemPropTypes.space.padding,
  borderRadius: StyledSystemPropTypes.border.border,
  color: StyledSystemPropTypes.color.color,
  highlightColor: StyledSystemPropTypes.color.color,
  overrideHeadingHighlighting: PropTypes.arrayOf(PropTypes.string),
  m: StyledSystemPropType,
  mb: StyledSystemPropType,
  ml: StyledSystemPropType,
  mr: StyledSystemPropType,
  mt: StyledSystemPropType,
  mx: StyledSystemPropType,
  my: StyledSystemPropType,
  p: StyledSystemPropType,
  pb: StyledSystemPropType,
  pl: StyledSystemPropType,
  pr: StyledSystemPropType,
  pt: StyledSystemPropType,
  px: StyledSystemPropType,
  py: StyledSystemPropType,
  headingLevel: PropTypes.oneOf(['h1', 'h2', 'h3']),
  textAlign: PropTypes.string,
  shouldWrapLongLines: PropTypes.bool,
  lineHeight: StyledSystemPropTypes.typography.lineHeight,
  justifyContent: PropTypes.string,
};

HeadingWithLineHighlights.defaultProps = {
  highlightBackgroundColor: null,
  highlightPadding: null,
  borderRadius: null,
  color: null,
  highlightColor: null,
  overrideHeadingHighlighting: null,
  headingLevel: 'h2',
  m: null,
  mb: null,
  ml: null,
  mr: null,
  mt: null,
  mx: null,
  my: null,
  p: null,
  pb: null,
  pl: null,
  pr: null,
  pt: null,
  px: null,
  py: null,
  shouldWrapLongLines: null,
  lineHeight: 1.4,
  textAlign: 'center',
  justifyContent: 'center',
};

export { HeadingWithLineHighlights };
